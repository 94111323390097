.container {
  display: flex;
  justify-content: center;
  width: 100%;
}


.logo {
  padding-top: 20px;
  max-width: 200px;
  height: auto;
}

.bannerContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
